import React, { Component } from 'react';
import $ from 'jquery';
import {windowManager} from './windowManager';
import {api} from './modules/api';
//import { api } from './modules/api';
import { Link } from 'react-router-dom';
import lang from "@/lang/se.json";
export class TopPanel extends Component{

  constructor( props ){
    super(props);

    this.state = {
      windows : props.windows,
      hasAlerts : false,
      checkedAlerts: props.checkedAlerts || false,
      alerts : [],
      displayAlerts: false
    }
    this.wm = new windowManager();
    this.handleClick = this.handleClick.bind(this);

    this.menu = React.createRef();
    this.menuButton = React.createRef();
    this.customerButton = React.createRef();

  }

  handleClick( e ){
    let windowId = $(e.target).data('id');
    this.wm.focusWindow( windowId );

  }
  showTime(){
    var date = new Date();
    var h = date.getHours(); // 0 - 23
    var m = date.getMinutes(); // 0 - 59
    var s = date.getSeconds(); // 0 - 59
//    var session = "AM";

    if(h === 0){
        h = 24;
    }

    if(h > 24){
        h = h - 24;
//        session = "PM";
    }

    h = (h < 10) ? "0" + h : h;
    m = (m < 10) ? "0" + m : m;
    s = (s < 10) ? "0" + s : s;

    var time = h + ":" + m + ":" + s ;

    let display = document.getElementById("MyClockDisplay");

    if( typeof display !== 'undefined' && display !== null ){
      display.innerText = time;
      display.textContent = time;
      this.timer = setTimeout( () => { this.showTime() }, 1000);

    }


  }
  handleLogout = (e) => {
    clearTimeout(this.timer);
    this.props.handleLogout(e);
  }
/*
  handleKeyPress = (e) => {

  if( e.key === "Escape" ){
      return this.toggleMenu();
    }
    return true;

  }
  toggleMenu = () => {

    const menu = this.menu.current;

    $(menu).toggleClass('active');


  }
*/
  clearMenu = () => {
    $(this.menu.current).removeClass('active');
  }

  componentDidMount(){
    this.showTime();
//    window.addEventListener('keydown', this.handleKeyPress);



  }
  async componentDidUpdate(){

    if( this.state.checkedAlerts !== true ){
      const response = await api.send('Employee', 'employee/hasDeactivated', {},{ type: 'GET' });

      let hasAlerts = ( response.data.length > 0 ? true : false );
      this.setState({ hasAlerts, checkedAlerts: true, alerts: response.data })
    }
  }
  openclick = (item, e) => {
    e.stopPropagation();

    this.props.openWindow(item.id, {
      module:'Employee',
      iconSrc   : api.Employee.iconSrc,
      api_query : api.Employee.read,
      titleColumns: ['fnamn','enamn'],
    });
    this.setState({ displayAlerts: false });
  }
  openAlerts = (e) => {
    const alerts = this.state.displayAlerts;
    this.setState({ displayAlerts: !alerts });

  }
  renderAlertContents(){

    let items = this.state.alerts.map( (item,i) => {

      return (
        <li onClick={ (e) => this.openclick(item,e) } className="alert-item dropdown-item" key={i}>
          <h6><small style={{ position:'absolute', left:0, top:4 }}>{ item.id } </small> { item.fnamn } { item.enamn }</h6>
        </li>
      );
    });

    return (
      <div className="alert-content">
        <h4>Följande användare har begärt radering: </h4>
        { items }
        <div className="alert alert-secondary">
          <h5>Att göra</h5>
          <p>1. Kontrollera att kundens personuppgifter är borttagna ur systemet</p>
          <p>2. Sök igenom ordermailen (samtliga år) och ta bort all mailkonversation från/till berörd person</p>
          <p>3. Gå igenom pärmar för att säkerställa att det inte finns någon dokumentation kvar.</p>
        </div>
      </div>
    );
  }
  render(){

    let items = this.props.items;

    if( items === 'undefined' || items == null )
      items = [];

    return (
      <div id="top-panel">
        <div id="panel-wrapper">

          <ul id="icon-container">
            <li onClick={this.toggleMenu} ref={this.menuButton} className="menu-button" title={"Menu"}>
              <Link to="/orders" data-key="F5" style={{ color: '#555' }} >
                  <img src="/assets/images/icons/menu.svg" alt="Toggle menu " />
                  <span id="MyClockDisplay"></span>
              </Link>
            </li>
            {
              items.map ( (window,i) => {

                 return (
                    <li onClick={this.handleClick} key={i} id={"icon-"+window.windowId} className="item top-panel-window-icon" title={ lang.module_labels[window.api_query] } >

                      <img className="icon" data-id={window.windowId} src={ window.iconSrc } alt={ window.iconSrc } />
                      { window.hasChanges === true && <img alt={'image_'+lang['there_are_unsaved_changes']} title={ lang['there_are_unsaved_changes'] } className="top-panel-window-icon-small-alert"  src={"assets/images/icons/software-update-urgent-symbolic.svg"} /> }
                    </li>
                  );
              })
            }
          </ul>

          <div onClick={this.handleLogout} id="logout" className=" pull-right" title={ window.title } >
            <i className="fas fa-sign-out-alt" />
          </div>
          {
            this.state.hasAlerts === true &&
            <div onClick={this.openAlerts} id="alerts" className="indicator text-warning pull-right " title={ lang['alerts'] } >
              <img src="/assets/images/icons/weather-severe-alert.svg" style={{width:28, height:'auto'}} />
              {
                this.state.displayAlerts === true &&
                <div className="alerts-wrapper">
                  { this.renderAlertContents() }
                </div>
              }
            </div>
          }

        </div>
      </div>
    )

  }
}
