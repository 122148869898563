import React, { Component } from 'react';
import './App.css';
import {moduleRouter} from './config.js';
import {TopPanel} from './topPanel.jsx';
import {Window} from './window.jsx';
import {HashRouter as Router, Route } from 'react-router-dom';
import {windowManager} from './windowManager.js';
import {uniqId} from './uniqid.js';
import {api} from './modules/api.js';
import { CustomerModule } from './modules/CustomerModule.jsx';
import { TemplateModule } from './modules/TemplateModule.jsx';
import { ArticleModule } from './modules/ArticleModule.jsx';
import { CustomerGroupModule } from './modules/CustomerGroupModule.jsx';
import { AccessoryModule } from './modules/AccessoryModule.jsx';
import { StickerModule } from './modules/StickerModule.jsx';
import { EmployeeModule } from './modules/EmployeeModule.jsx';
import { OrderModule } from './modules/OrderModule.jsx';
import { AddressModule } from './modules/AddressModule.jsx';
import { QuoteModule } from './modules/QuoteModule.jsx';
import { NewsModule } from './modules/NewsModule.jsx';
import {AdminModule} from './modules/AdminModule.jsx';
import { PricesModule } from './modules/PricesModule.jsx';
import StatisticsModule from './modules/StatisticsModule.jsx';
import { Login } from './login.jsx';
import { Logout } from './logout.jsx';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import lang from "@/lang/se.json";

class Admin extends Component {

  constructor( props ){
    super(props);

    this.wm = new windowManager();
    let user = ( JSON.parse(localStorage.getItem('user')) || false );
    let isAuthenticated = false;
    if( user !== false )
      isAuthenticated = true;

    this.state = {
      openWindows : this.wm.getWindows(),
      activeModule: moduleRouter[window.location.pathname.substring(1).split('/')],
      user: user,
      isAuthenticated: isAuthenticated
    };
    this.appendWindow = this.appendWindow.bind(this);
    this.update = this.update.bind(this);
    this.focusWindow = this.focusWindow.bind(this);
    this.openWindow = this.openWindow.bind(this);

  }
  _makeWindowTitle( object, params )
  {
    let fields = [];
    for(var i in params ){
      let field = params[i];
      if( field.indexOf(':') > -1 ){
        field = field.replace(':','');
        fields.push( field );
      } else {
        fields.push(object[field]);
      }

    }

    return fields.join(" ");


  }

  authenticateUser = ( userData ) => {
    const {name,expire,priv,token } = userData;

    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('token', token );

    this.setState({
      user: {
        name: name,
        expire: expire,
        priv: priv,
        token: token
      },
      isAuthenticated: true
    });




  }

  handleLogout = async ( e ) => {

    const response = await api.logout();
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.setState({
        user: false,
        isAuthenticated: false
      });


  }

  openWindow( objectId, windowParams ){

    let windowId = ( typeof objectId === 'undefined' ? windowParams.module+'_window_'+uniqId() : windowParams.module+'_window_'+objectId );

    let data = false;


    windowParams.objectId = objectId;
    if( typeof windowParams.data !== 'undefined' )
      data = Object.assign({}, windowParams.data );

    if( !this.wm.windowExists( windowId ) ){
      let self = this;

      self.appendWindow( data, windowId, windowParams );
      self.wm.setBodyHeight( windowParams );

    } else {
      this.wm.focusWindow( windowId );

    }

  }
  update(){
    let windows = this.wm.getWindows();
    this.setState({ openWindows: windows });
  }

  focusWindow ( windowId ){
    this.wm.focusWindow( windowId );
  }

  appendWindow( object, windowId, params ){


    if( typeof windowId === 'undefined' || windowId === null )
      windowId = 'window_'+uniqId();

    if( !this.wm.windowExists( windowId ) ){

      let winSettings = {
        windowId    : windowId,
        orgWidth    : 800,
        orgHeight   : 600,
        orgTop      : 50,
        orgLeft     : '50%',
        iconContainer: 'icon-container',
        objectId    : object.id
//        object      : object
      };
      if( typeof params === 'object' )
        winSettings = Object.assign({},winSettings, params );

      this.wm.setWindow(winSettings, windowId );

      let openWindows = this.wm.getWindows();
      this.setState({ openWindows });

    } else {
      this.wm.focusWindow( windowId );
    }


  }

  prepareEditableAttributes( attributes, object ){
    var attrName;
    let attrs = [];
    for(attrName in attributes){
      let a = attributes[attrName];
      let data = object[attrName];
      let attr = {...a,  "name": attrName, "label" : lang[a.label], "data" : data  };
      attrs.push(attr);
    }
    return attrs;


  }
  toHHMMSS ( string ) {
      var sec_num = parseInt(string, 10); // don't forget the second param
      var hours   = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return hours+':'+minutes+':'+seconds;
  }
  render() {


//    let windows = this.state.openWindows;
    let {openWindows:windows, user} = this.state;
    let token = localStorage.getItem('token');
    let isAuthenticated = ( token !== null && this.state.isAuthenticated === true ? true : false );

    if( windows === null || windows.length === 0 )
      windows = [];
//

    return (
      <Router>
      <Route path="/logout" render={ props => ( <Logout  handleLogout={this.handleLogout} isAuthenticated={isAuthenticated}/> ) }  />
      <div className="app">
        {( isAuthenticated === false  ) &&
          <Login lang={lang} authenticate={this.authenticateUser}  />

        }
        { isAuthenticated === true &&
          <React.Fragment>
          <TopPanel items={windows} user={user} openWindow={this.openWindow} focusWindow={this.focusWindow} handleLogout={this.handleLogout}/>
          <div  id="mainBody">
            <div id="windowWrapper">
            {
              windows.map( (window,i) => {

                let index = parseInt(i+''+100);
                if( window !== null && window !== false ){

                  return (
                    <Window
                      window={window}
                      makeWindowTitle={this._makeWindowTitle}
                      updateState={this.update}
                      lang={lang}
                      openWindow={this.openWindow}
                      appendWindow={this.appendWindow}
                      key={window.windowId}
                      tabIndex={index}
                      prepareEditableAttributes={this.prepareEditableAttributes}
                      parent={window.parent}
                    />
                  );
                }
                return '';
              })
            }
            </div>
            <nav ref={this.menu} id="main-menu" className="main-menu active">
              <div className="main-menu-items">
                <Link to="/addresses" data-key="F6" >
                  <div className="main-menu-item">
                    <img src={ api.Address.iconSrc } alt={ lang['label_addresses'] } />
                    <span className="maim-menu-item-label">{ lang['label_addresses'] }</span>
                  </div>
                </Link>
                <Link to="/articles" data-key="F3" >
                <div className="main-menu-item">
                  <img src={ api.Article.iconSrc } alt={ lang['Articles'] } />
                  <span className="maim-menu-item-label">{ lang['Articles'] }</span>

                </div>
                </Link>
                <Link to="/employees" data-key="F2"  onKeyDown={this.handleKeyPress}>
                <div className="main-menu-item">
                  <img src={ api.Employee.iconSrc } alt={ lang['label_employees'] } />
                  <span className="maim-menu-item-label">{ lang['label_employees'] }</span>

                </div>
                </Link>
                <Link to="/customers" data-key="F1" >
                <div className="main-menu-item">
                <img src={ api.Customer.iconSrc } alt={ lang['label_customers'] } />
                <span className="maim-menu-item-label">{ lang['label_customers'] }</span>
                </div>
                </Link>
                <Link to="/orders" data-key="F5" >
                <div className="main-menu-item">
                  <img src={ api.Order.iconSrc } alt={ lang['label_orders'] } />
                  <span className="maim-menu-item-label">{ lang['label_orders'] }</span>

                </div>
                </Link>
                <Link to="/templates" data-key="F4" >
                <div className="main-menu-item">
                  <img src={ api.Template.iconSrc } alt={ lang['templates'] } />
                  <span className="maim-menu-item-label">{ lang['templates'] }</span>

                </div>
                </Link>
                <Link to="/accessories" data-key="F4" >
                <div className="main-menu-item">
                  <img src={ api.Accessory.iconSrc } alt={ lang['accessories'] } />
                  <span className="maim-menu-item-label">{ lang['accessories'] }</span>

                </div>
                </Link>
                <Link to="/stickers" data-key="F4" >
                <div className="main-menu-item">
                  <img src={ api.Accessory.iconSrc } alt={ lang['stickers'] } />
                  <span className="maim-menu-item-label">{ lang['stickers'] }</span>

                </div>
                </Link>
                <Link to="/quotes" data-key="F4" >
                <div className="main-menu-item">
                  <img src={ api.Quote.iconSrc } alt={ lang['quotes'] } />
                  <span className="maim-menu-item-label">{ lang['quotes'] }</span>

                </div>
                </Link>
                <Link to="/stats" data-key="F4" >
                <div className="main-menu-item">
                  <img src={ api.Statistics.iconSrc } alt={ lang['statistics'] } />
                  <span className="maim-menu-item-label">{ lang['statistics'] }</span>
                </div>
                </Link>
                {
                  parseInt(this.state.user.priv) >= 2 &&
                  <React.Fragment>
                    <Link to="/prices" data-key="F4" >
                      <div className="main-menu-item">
                        <img src={ api.Prices.iconSrc } alt={ lang['prices'] } />
                        <span className="maim-menu-item-label">{ lang['prices'] }</span>

                      </div>
                    </Link>
                    <Link to="/customergroups" data-key="F4" >
                      <div className="main-menu-item">
                        <img src={ api.CustomerGroup.iconSrc } alt={ lang['customer_groups'] } />
                        <span className="maim-menu-item-label">{ lang['customer_groups'] }</span>
                      </div>
                    </Link>
                    <Link to="/admins" data-key="F4" >
                      <div className="main-menu-item">
                        <img src={ api.Admin.iconSrc } alt={ lang['admins'] } />
                        <span className="maim-menu-item-label">{ lang['admins'] }</span>
                      </div>
                    </Link>
                    <Link to="/news" data-key="F4" >
                      <div className="main-menu-item">
                        <img src={ api.News.iconSrc } alt={ lang['news'] } />
                        <span className="maim-menu-item-label">{ lang['news'] }</span>
                      </div>
                    </Link>
                  </React.Fragment>
                }
              </div>
            </nav>

              <div id="module-wrapper" style={{ marginLeft:200 }}>

                <Route path="/" exact render={ props => ( <OrderModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/articles" render={ props => ( <ArticleModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/customers" render={ props => ( <CustomerModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/orders" render={ props => ( <OrderModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/login" render={ props => ( <OrderModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/accessories" render={ props => ( <AccessoryModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/templates" render={ props => ( <TemplateModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/stickers" render={ props => ( <StickerModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/employees" render={ props => ( <EmployeeModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/addresses" render={ props => ( <AddressModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/quotes" render={ props => ( <QuoteModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                <Route path="/stats" render={ props => ( <StatisticsModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                {
                  this.state.user.priv >= 2 &&
                  <React.Fragment>
                    <Route path="/prices" render={ props => ( <PricesModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                    <Route path="/customergroups" render={ props => ( <CustomerGroupModule lang={lang} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                    <Route path="/admins" render={ props => ( <AdminModule lang={lang} user={this.state.user} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                    <Route path="/news" render={ props => ( <NewsModule lang={lang} user={this.state.user} appendWindow={this.appendWindow} openWindow={this.openWindow} prepareEditableAttributes={this.prepareEditableAttributes}/> ) }  />
                  </React.Fragment>
                }

              </div>
            </div>

          </React.Fragment>
        }

      </div>
      </Router>
    );
  }
}

export default Admin;
